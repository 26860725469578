// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import leads from '@src/views/apps/leads/store/reducer'
import clients from '@src/views/apps/clients/store/reducer'
import finance from '@src/views/apps/financeiro/store/reducer'
import dashboard from '@src/views/apps/dashboard/store/reducer'
import demandas from '@src/views/apps/demandas/store/reducer'
import selectOptions from '@src/views/apps/demandas/store/reducer/selectOptions'
import process from '@src/views/apps/demandas/store/reducer/process'
import cadastros from '@src/views/apps/cadastros/store/reducer'
import processStates from '@src/views/apps/demandas/store/reducer/processStates'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  leads,
  clients,
  finance,
  dashboard,
  demandas,
  process,
  selectOptions,
  cadastros,
  processStates
})

export default rootReducer

// ** Initial State
const initialState = {
  clients: [],
  numberContacts: [],
  contacts: [],
  policies: [],
  limitsCount: {
    sombrero: [],
    pottencial: []
  },
  clientLimits: {
    cliente: null,
    limits: {
      sombrero: [],
      pottencial: []
    }
  },
  clientNetWorth: [],
  clientProcesses: [],
  clientRecords: [],
  businessGroupByClient: [],
  clientObs: []
}

const ClientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CLIENTS':
      return {...state, clients: action.clients, offset: action.offset, count: action.count}
    case 'GET_POLICIES':
      return {...state, policies: [...state.policies, ...action.policies]}  
    case 'GET_CONTACTS':
      return { ...state, contacts: action.contacts }  
    case 'GET_NUMBER_CONTACTS':
      return { ...state, numberContacts: action.numberContacts }  
    case 'GET_ALL_LEADS':
      return {...state, leads: [...state.leads, ...action.leads]}
    case 'GET_LEADS_BY_CLIENT':
      return {...state, leads: action.leads }  
    case 'GET_LIMITS_COUNT':
      const insurance1 = action.insurance?.toLowerCase()
      return { ...state, limitsCount: { ...state.limitsCount, [insurance1]: [...state.limitsCount[insurance1], ...action.count]}}  
    case 'GET_CLIENT_NET_WORTH':
      return { ...state, clientNetWorth: action.clientNetWorth }  
    case 'GET_CLIENT_PROCESSES':
      return { ...state, clientProcesses: action.clientProcesses }  
    case 'GET_CLIENT_RECORDS':
      return { ...state, clientRecords: action.clientRecords }  
    case 'GET_CLIENT_OBS':
      return { ...state, clientObs: action.clientObs }  
    case 'GET_LIMITS':
      const insurance2 = action.insurance?.toLowerCase()
      return {
        ...state,
        clientLimits: {
          ...state.clientLimits,
          cliente: action.cliente,
          limits: {
            ...state.clientLimits.limits,
            [insurance2]: action.limits
          }
        }
      }
    case 'CLEAR_DATA':
      return {...state, leads: [], policies: [], limitsCount: { sombrero: [], pottencial: []}}  
    case 'GET_BUSINESS_GROUP':
      return { ...state, businessGroupByClient: action.businessGroup }  
    default:
      return state
  }
}
export default ClientsReducer
const initialState = {
  currentQuotationInsurance: null,
  reasonToLoss: {
    modalReasonToLoss: false,
    updateProcess: null,
    processId: null
  },
  reasonToCanceled: {
    modalReasonToCanceled: false,
    updateProcess: null,
    processId: null
  },
  hasPaidInstallments: false,
  generatingInstallments: null,
  editProcessStatus: null,
  applingFilter: false,
  expandAllCollapses: false,
  modalSendWhatsappMessage: false,
  modalAddEndorsement: false,
  modalColorConfigs: false
}
  
const ProcessStates = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CURRENT_QUOTATION_INSURANCE':
      return { ...state, currentQuotationInsurance: action.payload }
    case 'CHECK_PAID_INSTALLMENTS':
      return { ...state, hasPaidInstallments: action.payload }  
    case 'GENERATE_INSTALLMENTS':
      return { ...state, generatingInstallments: action.payload }  
    case 'EDIT_PROCESS':
      return { ...state, editProcessStatus: action.payload }  
    case 'SET_APPLING_FILTER':
      return { ...state, applingFilter: action.payload }
    case 'SET_EXPAND_ALL_COLLAPSES':
      return { ...state, expandAllCollapses: action.payload }   
    case 'SET_MODAL_SEND_WHATSAPP_MESSAGE':
      return { ...state, modalSendWhatsappMessage: action.payload }  
    case 'MODAL_ADD_ENDORSEMENT':
      return { ...state, modalAddEndorsement: action.payload }  
    case 'MODAL_COLOR_CONFIGS':
      return { ...state, modalColorConfigs: action.payload }
    case 'SET_MODAL_REASON_TO_LOSS':
      return { 
        ...state,
        reasonToLoss: {
          ...state.reasonToLoss,
          modalReasonToLoss: Boolean(action.updateProcess), 
          updateProcess: action.updateProcess,
          processId: action.processId  
        }
      }
    case 'SET_MODAL_REASON_TO_CANCELED':
      return { 
        ...state,
        reasonToCanceled: {
          ...state.reasonToCanceled,
          modalReasonToCanceled: Boolean(action.updateProcess), 
          updateProcess: action.updateProcess,
          processId: action.processId  
        }
      }
    default:
      return state
  }
}

export default ProcessStates